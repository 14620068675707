








































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Seminar, Submission } from "@/core/models";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import VideoRatings from "./VideoRatings.vue";
import VideoMenu from "./VideoMenu.vue";

@Component({ components: { VideoPlayer, VideoRatings, VideoMenu } })
export default class VideoSubmission extends Vue {
  @Prop({ default: () => false }) horizontal!: boolean;
  @Prop({ default: () => "unknown_video" }) id!: string;
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => undefined }) submission?: Submission;
  @Getter("profile/id") userId!: string;

  get isRateable() {
    return (this.submission as any).isRateable || false;
  }

  get isSkipped() {
    return this.submission?.skipped;
  }

  get submitter() {
    const user = this.seminar?.users?.find(
      x => x.id === this.submission?.userId,
    );
    if (!user) return "<<not_found>>";
    return `${user.username}`;
  }

  get elementTitle() {
    const el = this.seminar?.elements?.find(
      x => x.id === this.submission?.elementId,
    );
    if (!el) return "";
    const other = el.submissions?.filter(
      x => x.elementId === this.submission?.elementId,
    );
    if (!other || other.length < 2) return el.title;
    const idx = other?.findIndex(x => x.id === this.submission?.id);
    return `${el.title} (${idx + 1})`;
  }

  get canShare() {
    const el = this.seminar?.elements?.find(
      x => x.id === this.submission?.elementId,
    );
    if (!el || el.type !== "video_presentation") return false;

    const maxShared = el.maxNumShared || 0;
    const numShared =
      this.seminar?.submissions?.filter(
        x => x.elementId === el.id && x.isShared,
      ).length || 0;

    return numShared < maxShared;
  }

  handleRated(payload: any) {
    this.$emit("rated", payload);
  }

  goToVideo() {
    if (!this.submission?.id) return;
    this.$router.push(`/seminars/submission/${this.submission.id}`);
  }
}
