




























































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import {
  RateVideoAction,
  Rating,
  Seminar,
  SimpleUser,
  Submission,
} from "@/core/models";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

@Component({ components: { VideoPlayer } })
export default class VideoRatings extends Vue {
  @Prop({ default: () => false }) extended!: boolean;
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => undefined }) submission?: Submission;
  @Getter("profile/id") userId!: string;
  @Getter("seminars/users") users!: SimpleUser[];
  @Action("seminars/rateVideo")
  rateVideo!: (d: RateVideoAction) => Promise<void>;

  get ownSub() {
    return this.submission?.userId === this.userId;
  }

  get isShared() {
    return (this.submission as any).isShared || false;
  }

  get isRateable() {
    return (this.submission as any).isRateable || false;
  }

  rateLoading = false;
  typeLoading = "";
  async rate(type: string) {
    if (!this.submission?.id) return;
    if (this.rateLoading) return;

    this.rateLoading = true;
    this.typeLoading = type;
    await this.rateVideo({
      submissionId: this.submission.id,
      type,
    });
    this.$emit("rated", {
      submissionId: this.submission.id,
      type,
    });
    this.typeLoading = "";
    this.rateLoading = false;
  }

  isLoading(type: string) {
    return this.rateLoading && this.typeLoading === type;
  }

  countRatings(type: string) {
    const ratings = (this.submission as any)?.videoRatings as
      | Rating[]
      | undefined;
    if (!ratings?.length) return 0;
    return ratings.filter(x => x.type === type).length;
  }

  hasRated(type: string) {
    const ratings = (this.submission as any)?.videoRatings as
      | Rating[]
      | undefined;
    if (!ratings?.length) return false;
    return ratings.some(x => x.type === type && x.rater === this.userId);
  }

  selectedType = "";
  ratingsDialog = false;
  ratings: Rating[] = [];
  openRatings() {
    if (!this.submission) return;
    this.ratingsDialog = true;
    this.ratings = (this.submission as any).videoRatings;
  }
  get filteredRatigns() {
    if (!this.selectedType) return this.ratings;
    return this.ratings.filter(x => x.type === this.selectedType);
  }
  getUser(id: string) {
    const u = this.users.find(x => x.id === id);
    if (!u) {
      if (id === this.seminar?.owner?.id)
        return `${this.seminar.owner.username} (${this.seminar.owner.email}) - Owner`;
      return "";
    }
    return `${u.username} (${u.email})`;
  }
}
